<template>
  <v-container>
    <div
      class="d-flex align-space-between align-md-center justify-space-between flex-column flex-md-row mb-8"
    >
      <header-view :title="'Jogadores'" class="mb-8 mb-md-0" />

      <section class="d-flex justify-end">
        <switch-show-type />

        <v-btn
          color="primary"
          class="black--text"
          depressed
          disabled
          @click="createLeague()"
        >
          Criar novo
        </v-btn>
      </section>
    </div>

    <filter-input
      class="mb-8"
      :dataFilters="require('@/assets/league/filters/players.json')"
    />

    <!-- grid mode -->
    <card-grid
      v-if="$store.getters.viewType === 'grid'"
      :items="_players"
      :loading="loading"
      :pagination="pagination"
      @update:pagination="handlePagination($event)"
      @submit="openPlayer($event)"
    />

    <!-- list mode -->
    <custom-table
      v-else
      :headers="headers"
      :items="players"
      :pagination="pagination"
      :loading="loading"
      @update:pagination="handlePagination($event)"
    >
      <template v-slot:[`item.user`]="{ item }">
        <v-list-item class="px-0">
          <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp" tile>
            <v-img
              :src="item.avatar || require('@/assets/league/empty-user.png')"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <router-link
                class="overtext--text"
                :to="`/league/player-details/${item.id}`"
              >
                {{ item.xData.firstName }} {{ item.xData.lastName }}
              </router-link>
            </v-list-item-title>

            <v-list-item-subtitle v-if="!!item.nickname">
              {{ item.nickname }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-avatar v-if="$vuetify.breakpoint.xsOnly" tile>
            <v-img
              :src="item.avatar || require('@/assets/league/empty-user.png')"
            />
          </v-list-item-avatar>
        </v-list-item>
      </template>

      <template v-slot:[`item.schoolCertificate`]="{ item }">
        <a
          v-if="item.schoolCertificate"
          :href="item.schoolCertificate"
          target="_blank"
        >
          Visualizar
        </a>

        <div v-else class="subtext--text text-h5">--</div>
      </template>

      <template v-slot:[`item.contact`]="{ item }">
        <v-list-item class="px-0">
          <v-list-item-content>
            <v-list-item-title>{{ item.xData.email }}</v-list-item-title>

            <v-list-item-subtitle>{{ item.xData.phone }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-slot:[`item.genre`]="{ item }">
        {{ handleGenre(item.genre) }}
      </template>

      <template v-slot:[`item.institution`]="{ item }">
        <v-list-item
          v-if="!!item.school && Object.keys(item.school).length > 0"
          class="px-0"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ item.school.name }}
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ item.school.city }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-else-if="!!item.schoolComplementName" class="px-0">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.schoolComplementName }}
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ item.schoolComplementCity }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <div v-else class="subtext--text text-h5">--</div>
      </template>

      <template v-slot:[`item.grade`]="{ item }">
        {{ handleGrade(item.grade) }}
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
    </custom-table>
  </v-container>
</template>

<script>
import { getPlayers } from "@/services/league/players.js";
import { formatDate } from "@/utils";

export default {
  data() {
    return {
      loading: true,

      pagination: {
        page: 0,
        size: 20,
        total: 1,
      },
      headers: [
        {
          text: "Jogador",
          value: "user",
        },
        {
          text: "Comprovante escolar",
          value: "schoolCertificate",
        },
        {
          text: "CPF",
          value: "document",
        },
        {
          text: "Contato",
          value: "contact",
        },
        {
          text: "Genêro",
          value: "genre",
        },
        {
          text: "Instituição",
          value: "institution",
        },
        {
          text: "Ensino",
          value: "grade",
        },
        {
          text: "Data de Registro",
          value: "createdAt",
        },
        {
          value: "actions",
        },
      ],
      players: [],
    };
  },

  beforeMount() {
    this.getData();
  },

  watch: {
    ["$route.query"](value) {
      this.handleQuery(value);
    },
  },

  computed: {
    _players() {
      return this.players.map((e) => {
        return {
          id: e.id,
          image: e.avatar || require("@/assets/league/empty-user.png"),
          title: e.nickname,
          subtitle: `${e.xData.firstName} ${e.xData.lastName}`,
          icon: e.schoolCertificate ? "mdi-check-decagram-outline" : "",
        };
      });
    },
  },

  methods: {
    async getData(filter = {}) {
      try {
        const payload = {
          page: this.pagination.page,
          size: this.pagination.size,
          ...filter,
        };

        await getPlayers(payload).then((res) => {
          this.players = res.data;
          this.pagination.total = res.total;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleGenre(value) {
      switch (value) {
        case "F":
          return "Feminino";
        case "M":
          return "Masculino";
        case "O":
          return "Outro";
      }
    },

    handleGrade(value) {
      switch (value) {
        case "f":
          return "Fundamental";
        case "m":
          return "Médio";
        case "s":
          return "Superior";
        default:
          return "--";
      }
    },

    handleQuery(value) {
      this.loading = true;
      this.pagination.page = 0;
      this.getData(value);
    },

    handlePagination(event) {
      this.pagination.page = event;
      this.getData();
    },

    openPlayer(event) {
      this.$router.push({ path: "/league/player-details/" + event });
    },

    formatDate,
  },
};
</script>

<style></style>
